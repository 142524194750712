import * as rg4js from 'raygun4js';
import { GlobalErrorHandler } from './core/global-error-handler';
import { EnvironmentLoaderService } from 'src/app/core/services/environment-loader.service';
import { Injectable, Injector } from '@angular/core';

const VERSION_NUMBER = '1.0.0.0';

@Injectable()
export class RaygunErrorHandler extends GlobalErrorHandler {
  constructor(
    injector: Injector,
    private envService: EnvironmentLoaderService
  ) {
    super(injector);
  }

  handleError(e: any) {
    rg4js('apiKey', this.envService.environment.raygunApiKey);
    rg4js('setVersion', VERSION_NUMBER);
    rg4js('enableCrashReporting', this.envService.environment.raygunEnabled);
    super.handleError(e);
    if (this.envService.environment.raygunEnabled) {
      rg4js('send', {
        error: e
      });
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { TimeoutModalComponent } from './shared/modals/timeout-modal/timeout-modal.component';
import { IdleModule } from './core/services/idle/idle.module';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CoreModule } from './core/core.module';
import { API_BASE_URL } from './core/services/api.client.generated';
import { TimeoutModalModule } from './shared/modals/timeout-modal/timeout-modal.module';
import { EnvironmentLoaderService } from './core/services/environment-loader.service';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  ProtocolMode
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthStateService } from './core/services/auth.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // angular
    BrowserModule,
    BrowserAnimationsModule,
    // 3rd party
    // core & shared
    CoreModule,
    SharedModule,
    // app
    AppRoutingModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      timeOut: 5000
    }),
    IdleModule.forRoot({ idle: 12 * 60, timeout: 3 * 60 }),
    TimeoutModalModule,
    HttpClientModule,
    MsalModule
  ],
  providers: [
    AuthStateService,
    EnvironmentLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvironmentLoaderService) => {
        return () =>
          envService.loadConfig(
            `/environment.json`,
            `/auth.clientConfiguration.json?v=${Date.now()}`
          );
      },
      multi: true,
      deps: [EnvironmentLoaderService]
    },
    {
      provide: API_BASE_URL,
      useFactory: (envService: EnvironmentLoaderService) => {
        return envService.baseUrl;
      },
      deps: [EnvironmentLoaderService]
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: (
        envService: EnvironmentLoaderService
      ): IPublicClientApplication => {
        return new PublicClientApplication({
          auth: {
            clientId: envService.config.clientId,
            authority: envService.config.authority,
            redirectUri: envService.config.redirectUri,
            postLogoutRedirectUri: '/',
            knownAuthorities: [new URL(envService.config.authority).host],
            protocolMode: ProtocolMode.AAD
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
          },
          system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
              loggerCallback: (logLevel: LogLevel, message: string) =>
                console.log(message),
              logLevel: LogLevel.Error,
              piiLoggingEnabled: false
            }
          }
        });
      },
      deps: [EnvironmentLoaderService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (
        envService: EnvironmentLoaderService
      ): MsalGuardConfiguration => {
        return {
          interactionType: InteractionType.Redirect,
          authRequest: {
            scopes: envService.config.scope.split(' '),
            extraQueryParameters: {
              ui_locales: 'en'
            }
          },
          loginFailedRoute: '/'
        };
      },
      deps: [EnvironmentLoaderService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (
        envService: EnvironmentLoaderService
      ): MsalInterceptorConfiguration => {
        const protectedResourceMap = new Map<string, Array<string>>();

        // unprotected apis
        protectedResourceMap.set(
          `${envService.environment.apiEndpoint}/countries`,
          null
        );
        protectedResourceMap.set(
          `${envService.environment.apiEndpoint}/agreements?placeholder=register`,
          null
        );
        protectedResourceMap.set(
          `${envService.environment.apiEndpoint}/form/wealth-sources`,
          null
        );
        protectedResourceMap.set(
          `${envService.environment.apiEndpoint}/form/purposes-of-accounts`,
          null
        );
        protectedResourceMap.set(
          `${envService.environment.apiEndpoint}/form/create`,
          null
        );

        // protected apis
        protectedResourceMap.set(
          envService.environment.apiEndpoint,
          envService.config.scope.split(' ')
        );

        return {
          interactionType: InteractionType.Redirect,
          protectedResourceMap
        };
      },
      deps: [EnvironmentLoaderService]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}

<nav class="navbar">
  <div class="container d-flex align-items-center">
    <a href="" class="navbar-brand">
      <img
        src="{{ cdnHostname }}/brand/logo/fdt-rgb-horizontal-dark.png"
        alt="logo"
      />
    </a>
    <ul class="navbar-nav navbar-collapse" *ngIf="showSiteMap">
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://1stdigital.com/solutions/"
          target="_blank"
        >
          Solutions
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://1stdigital.com/news-and-insights/"
          target="_blank"
        >
          News &amp; Insights
        </a>
      </li>
      <li class="nav-item dropdown dropdown-hover">
        <a
          class="nav-link"
          href="https://1stdigital.com/about-us/"
          target="_blank"
          >Company
        </a>
      </li>
    </ul>
    <ul class="d-flex align-items-center m-0" *ngIf="isAuthenticated">
      <li>
        <app-ng-lib-button
          type="cancel"
          label="Sign Out"
          (onClick)="logout()"
        ></app-ng-lib-button>
      </li>
    </ul>
  </div>
</nav>

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html'
})
export class ContentLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}

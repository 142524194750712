import { ModuleWithProviders, NgModule } from '@angular/core';
import {IdleConfig} from './idle.config';

@NgModule({
  imports: []
})
export class IdleModule {
  static forRoot(config: IdleConfig): ModuleWithProviders<IdleModule> {
    return {
      ngModule: IdleModule,
      providers: [
        {provide: IdleConfig, useValue: config}
      ]
    };
  }
}

import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {ApplicationsListItem} from './api.client.generated';

const ACTIVE_APPLICATION = 'active_application';

@Injectable({
  providedIn: 'root'
})
export class ApplicationContextService {

  constructor() { }

  setActiveApplication(application: ApplicationsListItem) {
    localStorage.setItem(ACTIVE_APPLICATION, JSON.stringify(application));
  }

  clear() {
    localStorage.removeItem(ACTIVE_APPLICATION);
  }

  getActiveApplication() {
    const application = JSON.parse(localStorage.getItem(ACTIVE_APPLICATION)) as ApplicationsListItem;
    if (application != null) {
      return application;
    }
    throwError('Client context is null');
  }

  isSet() {
    const application = JSON.parse(localStorage.getItem(ACTIVE_APPLICATION)) as ApplicationsListItem;
    return (application != null);
  }
}

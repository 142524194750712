/*
The SharedModule is where any shared components, pipes/filters and services should go.
The SharedModule can be imported in any other module when those items will be re-used.
The shared module shouldn’t have any dependency to the rest of the application and should
therefore not rely on any other module.
Read more:
https://itnext.io/choosing-a-highly-scalable-folder-structure-in-angular-d987de65ec7
*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLibButtonComponent } from './components/ng-lib-button/ng-lib-button.component';
import { LoaderModalComponent } from './modals/loader-modal/loader-modal.component';
import { GuidelineModalComponent } from './modals/guideline-modal/guideline-modal.component';
import { BannerComponent } from './components/banner/banner.component';

const LibraryComponents = [NgLibButtonComponent];
@NgModule({
  declarations: [
    BannerComponent,
    LoaderModalComponent,
    GuidelineModalComponent,
    ...LibraryComponents
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LoaderModalComponent,
    GuidelineModalComponent,
    NgbModule,
    BannerComponent,
    ...LibraryComponents
  ]
})
export class SharedModule {}

<div class="modal-header d-flex justify-content-between">
  <div>
    <span class="h4 modal-title">Did you need more time?</span>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p>
    For your protection, you application will time out in 15 minutes if there's no activity.
    Your information will not be saved, and you'll have to start a new application.
  </p>
  <div class="text-left">
    <button type="submit" class="btn btn-primary" (click)="activeModal.close()">Return to application</button>
  </div>
</div>

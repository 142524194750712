import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApplicationContextService } from '../services/application-context.service';
import { switchMap } from 'rxjs/operators';
import { VerifyStepService } from '../services/verify-step.service';
import {
  ApplicationFormStatusResponse,
  ApplicationsService,
  LegalEntityType
} from '../services/api.client.generated';

@Injectable()
export class ApplicationFormGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private applicationContextService: ApplicationContextService,
    private applicationsService: ApplicationsService,
    private stepService: VerifyStepService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log('ApplicationFormGuard, canActivate');
    return this.check(state);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.check(state);
  }

  private check(state: RouterStateSnapshot) {
    const application = this.applicationContextService.getActiveApplication();
    this.stepService.clear();
    let report$: Observable<ApplicationFormStatusResponse>;
    if (!application) {
      return of(null);
    } else {
      report$ = this.applicationsService.getReportByForm(application.id);
    }
    return report$.pipe(
      switchMap(x => {
        let command = '/form/individual';
        if (!x.accountStepPassed) {
          command += '/account';
        } else if (!x.verificationStepPassed) {
          command += '/verification';
          if (!x.isEmailVerified) {
            this.stepService.setStep(1);
          } else if (!x.isPhoneVerified) {
            this.stepService.setStep(2);
          } else if (!x.hasBankAccount) {
            this.stepService.setStep(3);
          } else if (!x.hasSupportingDocuments) {
            this.stepService.setStep(4);
          }
        } else if (!x.hasServices) {
          command += '/services';
        } else {
          command += '/confirmation';
        }

        if (state.url === command) {
          return of(true);
        }

        return of(this.router.parseUrl(command));
      })
    );
  }
}

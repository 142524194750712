import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

fetch('/environment.json')
  .then(response => response.json())
  .then(envConfig => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .then(() => {
        const envObject: any = Object.values(envConfig).find((env: any) =>
          location.host.includes(env.host)
        );
        if (envObject && envObject.production) {
          enableProdMode();
          document.querySelector('head').insertAdjacentHTML('beforeend', `<script async src="https://www.googletagmanager.com/gtag/js?id=UA-120458213-2"></script>
            <script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag("js", new Date());
              gtag("config", "UA-120458213-2");
            </script>
          `);
        }
      });
  })
  .catch(err => console.log(err));

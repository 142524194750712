import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
  ChangeDetectionStrategy,
  TemplateRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const BUTTON_TYPE_DEFAULT_MAPPING: { [key: string]: any } = {
  submit: {
    type: 'submit',
    class: 'btn-primary',
    label: 'Submit'
  },
  cancel: {
    type: 'reset',
    class: 'btn-secondary',
    label: 'Cancel'
  },
  danger: {
    type: 'button',
    class: 'btn-danger'
  },
  button: {
    type: 'button',
    class: ''
  }
};
@Component({
  selector: 'app-ng-lib-button',
  templateUrl: './ng-lib-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgLibButtonComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgLibButtonComponent implements OnInit {
  constructor() {}

  @Input() label: string = '';
  @Input() type: 'submit' | 'cancel' | 'danger' | 'button' = 'button';
  @Input() size: 'small' | 'medium' = 'medium'; // reserve the right to add 'large'
  @Input() styleClass: string = '';
  @Input() icon: string = '';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() iconOnly: boolean = false;

  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() loadingLabel: string = 'Loading';
  @Input() contentTemplate!: TemplateRef<any>;

  @Output() onClick: EventEmitter<any> = new EventEmitter<Event>();

  @Output() onFocus: EventEmitter<any> = new EventEmitter<Event>();

  @Output() onBlur: EventEmitter<any> = new EventEmitter<Event>();

  get btnDefaultSetting() {
    return BUTTON_TYPE_DEFAULT_MAPPING[this.type] || null;
  }

  ngOnInit(): void {}
}

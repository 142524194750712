import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EnvironmentLoaderService } from 'src/app/core/services/environment-loader.service';

@Injectable({
  providedIn: 'root'
})
export class PlausibleService {
  ngUnsub = new Subject<void>();
  dataDomain: string = this.envService.environment.plausibleDataDomain;

  constructor(private envService: EnvironmentLoaderService) {}

  load() {
    var script = document.createElement('script');
    script.defer = true;
    script.src = 'https://plausible.io/js/script.js';
    script.crossOrigin = 'anonymous';
    script.dataset['domain'] = this.dataDomain;
    document.head.append(script);
  }

  ngOnDestroy(): void {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

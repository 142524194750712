export const MIN_SIZE = 1024;
export const MAX_SIZE = 25 * 1024 * 1024;
export const FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
export const NO_TAX_REASON_VALUES = {
  1: 'The country where the Account Holder is liable to pay tax does not issue TINs to its residents',
  2: 'The Account Holder is otherwise unable to obtain a TIN or equivalent number',
  3: 'No TIN is required'
};

export const UNAUTHORIZED_MESSAGE =
  'Please try signing out and signing back in with your new account.';

export const AUTH_EXPIRED_MESSAGE =
  'Session timeout, please sign out and sign back in to complete your application.';

export const AGREEMENT_BOX_HEIGHT = 160;

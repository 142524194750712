import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { EnvironmentLoaderService } from '../services/environment-loader.service';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private envService: EnvironmentLoaderService,
    private authService: MsalService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestToForward = req;

    const activeAccount = this.authService.instance.getActiveAccount();
    activeAccount &&
      this.authService
        .acquireTokenSilent({
          account: activeAccount,
          scopes: this.envService.config.scope.split(' ')
        })
        .subscribe((authResult: AuthenticationResult) => {
          const tokenValue = 'Bearer ' + authResult.accessToken;
          requestToForward = req.clone({
            setHeaders: { Authorization: tokenValue }
          });
        });

    return next.handle(requestToForward);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { IdleService } from './core/services/idle/idle.service';
import { TimeoutModalComponent } from './shared/modals/timeout-modal/timeout-modal.component';
import { PlausibleService } from './core/services/plausible.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthStateService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsub = new Subject();
  private modal: NgbModalRef;
  isIframe = false;

  constructor(
    private authStateService: AuthStateService,
    private idle: IdleService,
    private modalService: NgbModal,
    private router: Router,
    private plausibleService: PlausibleService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setActiveAccount();
        this.navigateToStoredEndpoint();
      });

    this.authStateService.setIsAuthenticated();

    this.idle
      .onTimerStart()
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(count => {
        if (count === 1) {
          this.openModal();
        }
      });

    this.idle
      .onTimeout()
      .pipe(takeUntil(this.ngUnsub))
      .subscribe(() => this.modal.dismiss());
    this.plausibleService.load();
  }

  setActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  openModal() {
    this.modal = this.modalService.open(TimeoutModalComponent);
    this.modal.result.then(
      x => this.idle.stopTimer(),
      x => {
        //       sessionStorage.clear();
        //       localStorage.clear();
        //       const cookies = document.cookie.split(';');
        //       for (let i = 0; i < cookies.length; i++) {
        //         const cookie = cookies[i];
        //         const eqPos = cookie.indexOf('=');
        //         const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //         document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        //       }
        this.router.navigate(['/']);
      }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsub.next(null);
    this.ngUnsub.complete();
  }

  private navigateToStoredEndpoint() {
    const path = this.read('redirect');

    if (this.router.url === path) {
      return;
    }

    if (path && path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else if (path) {
      localStorage.removeItem('redirect');
      this.router.navigate([path]);
    }
  }

  private read(key: string): any {
    const data = localStorage.getItem(key);
    return data;
  }

  private write(key: string, value: any): void {
    localStorage.setItem(key, value);
  }
}

/*
The CoreModule should contain singleton services (which is usually the case),
universal components and other features where there’s only once instance per application.
To prevent re-importing the core module elsewhere, you should also add a guard for
it in the core module’ constructor.

Source:
https://itnext.io/choosing-a-highly-scalable-folder-structure-in-angular-d987de65ec7
*/

import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { ApplicationsGuard } from './guards/applications.guard';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { ApplicationFormInterceptor } from './interceptors/application-form.interceptor';
import { ApplicationFormGuard } from './guards/application-form.guard';
import { RaygunErrorHandler } from '../app.raygun.setup';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    { provide: ErrorHandler, useClass: RaygunErrorHandler },
    ApplicationsGuard,
    ApplicationFormGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationFormInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

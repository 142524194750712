import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { EnvironmentLoaderService } from './environment-loader.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthStateService {
  isAuthenticated: boolean = false;
  private _isAuthenticatedSource = new Subject<void>();
  isAuthenticated$ = this._isAuthenticatedSource.asObservable();

  constructor(
    private authService: MsalService,
    private envService: EnvironmentLoaderService
  ) {}

  public async setIsAuthenticated() {
    try {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (activeAccount) {
        const tokenResponse = await this.authService
          .acquireTokenSilent({
            account: this.authService.instance.getActiveAccount()!,
            scopes: this.envService.config.scope.split(' ')
          })
          .toPromise();
        if (tokenResponse?.accessToken) this.isAuthenticated = true;
        this._isAuthenticatedSource.next();
      }
    } catch (e) {
      console.log(e);
    }
  }
}

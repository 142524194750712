import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ApplicationContextService} from '../services/application-context.service';
import {switchMap} from 'rxjs/operators';
import { ApplicationsService} from '../services/api.client.generated';

@Injectable()
export class ApplicationsGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private applicationContextService: ApplicationContextService,
    private applicationsService: ApplicationsService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('ApplicationsGuard, canActivate');
    return this.check(state);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state);
  }

  private check(state: RouterStateSnapshot) {
    return this.applicationsService.getApplications()
      .pipe(switchMap(x => {
        if (x && x.length === 1) {
          this.applicationContextService.setActiveApplication(x[0]);
          return of(this.router.parseUrl('form/individual/verification'));
        } else {
          return of(true);
        }
      }));
  }
}


<footer class="footer footer-dark">
  <div class="container">
    <div class="row align-items-center justify-content-md-between row pb-md">
      <div class="col-md-6">
        <div class="copyright text-sm text-center text-md-left">
          © {{ currentYear }}&nbsp;<a href="https://1stdigital.com/news-and-insights/">First Digital</a>. All rights reserved.
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
          <li class="nav-item">
            <a class="nav-link" href="https://www.youtube.com/channel/UCIWlTS2V1optHhwen9IpbWg" target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="https://twitter.com/FirstDigitalHQ" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.linkedin.com/company/first-digital-trust" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { UtilityLayoutComponent } from './layout/utility-layout/utility-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SharedModule } from './shared/shared.module';
import { ApplicationsGuard } from './core/guards/applications.guard';
import { HeaderComponent } from './layout/header/header.component';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    data: { title: 'Open A New Account Online' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/landing-page/landing-page.module').then(
            x => x.LandingPageModule
          )
      }
    ]
  },
  {
    path: 'form',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('./modules/application-form/application-form.module').then(
        x => x.ApplicationFormModule
      )
  },
  {
    path: 'applications',
    component: ContentLayoutComponent,
    canActivate: [MsalGuard, ApplicationsGuard],
    loadChildren: () =>
      import('./modules/applications/applications.module').then(
        m => m.ApplicationsModule
      )
  },
  {
    path: '',
    component: UtilityLayoutComponent,
    children: [
      { path: 'forbidden', component: UnauthorizedComponent },
      { path: 'unauthorized', component: UnauthorizedComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled' // Set to enabledBlocking to use Angular Universal
    }),
    SharedModule
  ],
  declarations: [
    UnauthorizedComponent,
    ContentLayoutComponent,
    UtilityLayoutComponent,
    HeaderComponent,
    FooterComponent
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {ApplicationContextService} from '../services/application-context.service';

@Injectable()
export class ApplicationFormInterceptor implements HttpInterceptor {

  private applicationContextService: ApplicationContextService;

  constructor(private injector: Injector) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    this.applicationContextService = this.injector.get(ApplicationContextService);
    if (this.applicationContextService !== undefined) {
      if (this.applicationContextService.isSet()) {
        const applicationFormContext = this.applicationContextService.getActiveApplication();
        requestToForward = req.clone({setHeaders: {formId: applicationFormContext.id.toString()}});
      }
    }
    return next.handle(requestToForward);
  }
}

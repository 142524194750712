import { NgModule } from '@angular/core';
import { TimeoutModalComponent } from './timeout-modal.component';
import {SharedModule} from '../../shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [TimeoutModalComponent],
  exports: [TimeoutModalComponent]
})
export class TimeoutModalModule { }

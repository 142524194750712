<button
  [attr.type]="btnDefaultSetting?.type || type"
  [attr.aria-label]="label || btnDefaultSetting?.label"
  class="btn d-inline-flex justify-content-center align-content-center"
  [class.btn-small]="size === 'small'"
  [class.p-0]="iconOnly"
  [ngClass]="styleClass + ' ' + btnDefaultSetting?.class"
  [disabled]="disabled || isLoading"
  (click)="onClick.emit($event)"
  (focus)="onFocus.emit($event)"
  (blur)="onBlur.emit($event)"
>
  <ng-container *ngIf="!isLoading; else loading">
    <span
      *ngIf="icon"
      [ngClass]="{
        'btn-icon-left': !iconOnly && iconPosition === 'left',
        'btn-icon-right': !iconOnly && iconPosition === 'right'
      }"
    >
      <i [ngClass]="icon" aria-hidden="true"></i>
    </span>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    <span *ngIf="!contentTemplate">
      {{ label || btnDefaultSetting?.label }}</span
    >
  </ng-container>
  <ng-template #loading
    ><i class="fa fa-duotone fa-spin fa-spinner"></i
    >{{ loadingLabel }}</ng-template
  >
</button>

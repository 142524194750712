import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Subject, takeUntil } from 'rxjs';
import { AuthStateService } from 'src/app/core/services/auth.service';
import { EnvironmentLoaderService } from 'src/app/core/services/environment-loader.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() showSiteMap: boolean = true;
  cdnHostname: string = this.envService.environment.cdnHostname;
  isAuthenticated: boolean = this.authStateService.isAuthenticated;
  private ngUnsub = new Subject();

  constructor(
    private authStateService: AuthStateService,
    private authService: MsalService,
    private envService: EnvironmentLoaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.showSiteMap = this.router.url == '/';
    this.authStateService.isAuthenticated$.subscribe(() => {
      this.isAuthenticated = this.authStateService.isAuthenticated;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsub.next(null);
    this.ngUnsub.complete();
  }

  logout() {
    this.authService.logoutRedirect();
  }
}
